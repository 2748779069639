.text-bold {
    font-weight: bold
}

.text-bold-green {
    color: green;
    font-weight: bold
}

.text-green {
    color: green;
}

.text-bold-red {
    color: red;
    font-weight: bold
}

.text-bold-orange {
    color: #780ad2;
    font-weight: bold
}

.text-info2 {
    color: #780ad2 !important;
}

.overlay-bottom
{
    position: relative;
    top: 0;
    left: 0;
}
.overlay-top
{
    position: absolute;
    top: 0;
    left: 0;
}

.stickyside a {
    color: #780ad2;
}

.stickyside a.active {
    color: #FFFFFF;
    background: #780ad2;
    border-color: #780ad2;
}

.stickyside a:hover {
    color: #FFFFFF;
    background: #780ad2;
    border: 1px solid #780ad2;
}

.list-group a.list-group-item:hover {
    background: #780ad2;
}

.list-group-item.active,
.list-group .list-group-item.active:hover {
    background: #780ad2;
    border-color: #780ad2;
}

.list-group-item.disabled {
    color: #99abb4;
    background: #f2f4f8;
}